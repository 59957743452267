define([], function() {

  const classNames = {
    subjectWithoutMethod: 'accountMarketingOptionsForm_subjectWithoutMethodMessage',
    methodWithoutSubject: 'accountMarketingOptionsForm_methodWithoutSubjectMessage'
  };

  class AccountMarketingOptionsForm {
    constructor() {
      this.init = this.init.bind(this);
      this.clearMessages = this.clearMessages.bind(this);
      this.validateForm = this.validateForm.bind(this);
    }

    init(element) {
      element.addEventListener('submit', this.validateForm);
      this.element = element;
      this.subjectCheckboxes = Array.from(element.querySelectorAll('input[name^="relatingTo"]'));
      this.methodCheckboxes = Array.from(element.querySelectorAll('input[name^="contactVia"]'));
      this.subjectWithoutMethod = element.getElementsByClassName(classNames.subjectWithoutMethod)[0];
      this.methodWithoutSubject = element.getElementsByClassName(classNames.methodWithoutSubject)[0];
      this.subjectCheckboxes.concat(this.methodCheckboxes).forEach(checkbox => {
        checkbox.addEventListener('change', this.clearMessages);
      });
    }

    clearMessages() {
      this.subjectWithoutMethod.classList.remove(`${classNames.subjectWithoutMethod}-show`);
      this.methodWithoutSubject.classList.remove(`${classNames.methodWithoutSubject}-show`);
    }

    validateForm(event) {
      this.clearMessages();
      const subjectSelected = this.subjectCheckboxes.some(subjectCheckbox => (subjectCheckbox.checked));
      const methodSelected = this.methodCheckboxes.some(methodCheckbox => (methodCheckbox.checked));

      if (subjectSelected && !methodSelected) {
        this.subjectWithoutMethod.classList.add(`${classNames.subjectWithoutMethod}-show`);
        console.log('Subject without method');
        event.preventDefault();
      } else if (!subjectSelected && methodSelected) {
        this.methodWithoutSubject.classList.add(`${classNames.methodWithoutSubject}-show`);
        console.log('Method without subject');
        event.preventDefault();
      }
    }

  }

  return AccountMarketingOptionsForm;

});
